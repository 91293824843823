export function init(view,settings){
	view.attachEvent("onComponentInit", () => ready(view));

	var options = [];

	settings.modes.forEach(function(mode){
		if(mode == "table")
			options.push(getModeConfig(mode, "table"));
		else if(mode == "files")
			options.push(getModeConfig(mode, "icons"));
	});

	var config = { view: "segmented", width: 70, options: options, css:"webix_fmanager_modes", value: settings.mode};

	return config;
}

function getModeConfig(mode, tooltip){
	return {
		id: mode,
		width: 32,
		value: "<div class=\"webix_fmanager_bar_icon webix_fmanager_"+mode+"_mode \"></div>",
		tooltip:webix.i18n.filemanager[tooltip+"View"]
	};
}

function ready(view){
	if(view.$$("modes")){
		view.$$("modes").attachEvent("onBeforeTabClick",function(id){
			var value = view.$$("modes").getValue();
			if(view.callEvent("onBeforeModeChange",[value,id])){
				if(view.$$(id)){
					view.config.mode = id;
					view.$$(id).show();
					view.callEvent("onAfterModeChange",[value,id]);
					return true;
				}
			}
			return false;
		});
	}
}