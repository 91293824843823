import * as sorting from "./sort";

export function init(view){
	view.attachEvent("onBeforeCursorChange", function(){
		view.$skipDynLoading = false;
		return true;
	});
	setDataParsers(view);
}

function setDataParsers(view){
	view.dataParser = {
		files: function(obj, data){
			if(this.config.noFileCache){
				clearBranch(this, obj.id);
			}
			else
				obj.webix_files = 0;

			parseData(this, data);
		},
		branch : function(obj, data){
			if(this.config.noFileCache){
				clearBranch(this, obj.id);
			}
			else{
				obj.webix_branch = 0;
				obj.webix_child_branch = 0;
			}

			parseData(this, data);
		}
	};
}
export function loadDynData(view, url, obj, mode, open){
	view.showProgress();
	if(view.callEvent("onBeforeDynLoad", [url, obj, mode, open])){
		if (url.load)
			return url.load(null, { action: mode, source: obj.id})
				.then(
					function(data){
						view.hideProgress();
						data = convertData(view, data);
						if(open)
							obj.open = true;

						if(view.callEvent("onBeforeDynParse", [obj, data, mode])){
							view.dataParser[mode].call(view, obj, data);
							view.callEvent("onAfterDynParse", [obj, data, mode]);
						}
					},
					function(){
						view.hideProgress();
						view.callEvent("onDynLoadError",[]);
					}
				);
	}
}

export function clearBranch(view, id){
	var items = [];

	view.data.eachChild(id,function(item){
		if(!view.data.branch[item.id] && item.type != "folder")
			items.push(item.id);
	},view,true);

	for(var i=0; i< items.length; i++){
		view.remove(items[i]);
	}
}

export function parseData(view, data){
	view.parse(data);
	view.$skipDynLoading = true;
	view._refreshActiveFolder();
	view.$skipDynLoading = false;
}

export function openFolders(view, folders){
	var dynMode, i, pItem;
	var defer = webix.promise.defer();
	dynMode = getDynMode(view);

	if(dynMode && folders.length){
		for(i =0; i < folders.length; i++){
			pItem = view.getItem(folders[i]);
			if(!(pItem && !pItem["webix_" + dynMode])){
				openDynFolder(view,folders.slice(i), dynMode, defer);
				return defer;
			}
			else{
				pItem.open = true;
				if(view.$$("tree"))
					view.$$("tree").refresh(folders[i]);
			}
		}
		defer.resolve(folders[i]);
	}
	else{
		defer.reject();
	}
	return defer;
}
function openDynFolder(view,ids, mode, defer){
	var obj = view.getItem(ids[0]);
	view.showProgress();
	var url = view.config.handlers[mode];
	if (url.load)
		return url.load(null, { action: mode, source: ids[0]})
			.then(
				function(data){
					view.hideProgress();
					data = convertData(view, data);
					if(view.callEvent("onBeforeDynParse", [obj, data, mode])){
						obj.open = true;
						view.dataParser[mode].call(view, obj, data);

						var lastId = ids.shift();
						if(ids.length && view.getItem(ids[0]).type == "folder"){
							openDynFolder(view,ids, mode, defer);
						}
						else{
							view.refreshCursor();
							defer.resolve(lastId);
						}
						view.callEvent("onAfterDynParse", [obj, data, mode]);
					}
				}
			);
}

export function getDynMode(view){
	for(var mode in view.dataParser){
		if (view.config.handlers[mode]) {
			return mode;
		}
	}
	return null;
}
export function loadSearchData(view, url, id, value){
	var params =  { action:"search", source: id, text: value};
	if(view.callEvent("onBeforeSearchRequest",[id, params])){
		if (url.load)
			return url.load(null, params)
				.then(
					function(data){
						view.hideProgress();
						data = convertData(view, data);
						parseSearchData(view, data);
						view.$searchValue = value;
					},
					function(){
						view.hideProgress();
					}
				);
	}
}
export function parseSearchData(view, data){
	view.callEvent("onShowSearchResults",[]);
	view.$searchResults = true;
	var cell = view.$$(view.config.mode);
	if(cell && cell.filter){
		cell.clearAll();
		if(view.sortState && view.sortState.view == cell.config.id)
			data = sorting.sortData(view.sortState.sort, data);
		cell.parse(data);
	}
}

function convertData(view, data){
	if (data && typeof data.text === "function")
		data = data.text();
	return view.data.driver.toObject(data);
}