// locale values
webix.i18n.filemanager = {
	actions: "Actions",
	back: "Back",
	forward: "Forward",
	levelUp: "Level Up",
	name: "Name",
	size: "Size",
	type: "Type",
	date: "Date",
	copy: "Copy",
	cut: "Cut",
	paste: "Paste",
	upload: "Upload",
	remove: "Delete",
	create: "Create Folder",
	rename: "Rename",
	location: "Location",
	select: "Select Files",
	sizeLabels: ["B","KB","MB","GB"],
	iconsView: "Icons View",
	tableView: "Table View",
	hideTree: "Hide Tree",
	showTree: "Show Tree",
	collapseTree: "Collapse Tree",
	expandTree: "Expand Tree",
	saving: "Saving...",
	errorResponse: "Error: changes were not saved!",
	replaceConfirmation: "The folder already contains files with such names. Would you like to replace existing files ?",
	createConfirmation: "The folder with such a name already exists. Would you like to replace it ?",
	renameConfirmation: "The file with such a name already exists. Would you like to replace it ?",
	yes: "Yes",
	no: "No",
	types:{
		folder: "Folder",
		doc: "Document",
		excel: "Excel",
		pdf: "PDF",
		pp: "PowerPoint",
		text: "Text File",
		video: "Video File",
		image: "Image",
		code: "Code",
		audio: "Audio",
		archive: "Archive",
		file: "File"
	}
};
