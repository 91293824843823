export function makeSaveRequest(view, url, obj, callback){
	if(view.callEvent("onBeforeRequest",[url, obj])){
		showSaveMessage(view);
		if(url.load){
			url.load(null, webix.copy(obj))
				.then(
					function(data){
						if (data && typeof data.text === "function")
							data = data.text();
						data = view.data.driver.toObject(data);
						hideSaveMessage(view);
						if(view.callEvent("onSuccessResponse",[obj,data]) && callback)
							callback.call(view,obj,data);
					},
					function(error){
						if(view.callEvent("onErrorResponse", [obj, error]))
							errorHandler(view, error);
					}	
				);
		}
	}
}

function showSaveMessage(view, message){
	view._saveMessageDate = new Date();
	if (!view._saveMessage){
		view._saveMessage = webix.html.create("DIV",{ "class":"webix_fmanager_save_message"},"");
		view.$view.style.position = "relative";
		webix.html.insertBefore(view._saveMessage, view.$view);
	}
	var msg = "";
	if (!message) {
		msg = webix.i18n.filemanager.saving;
	} else{
		msg = webix.i18n.filemanager.errorResponse;
	}

	view._saveMessage.innerHTML = msg;
}

function hideSaveMessage(view){
	if (view._saveMessage){
		webix.html.remove(view._saveMessage);
		view._saveMessage = null;
	}
}

export function errorHandler(view){
	// reload data on error response
	const url = view.data.url;
	if(url){
		showSaveMessage(view, true);
		webix.ajax(url)
			.then((data)=>{
				if (data && typeof data.text === "function")
					data = data.text();
				data = view.data.driver.toObject(data);
				if (data){
					view.clearAll();
					view.parse(data);
					view.data.url = url;
				}
			});
	}
}